body{
    font-family: "Montserrat", sans-serif!important;
  }
  
  
  .mesHisto{
    background-color: white;
      margin-top: -10%;
      border-radius: 28px;
      padding-top:7%
  }
  
  .titreHisto{
    margin-bottom:5%;
    padding-left:2%;
    font-size: 2.2rem;
    text-align: center;
  }

  .pourboire{
    text-align: center;
}
@media (max-width: 700px){
  .table thead {
  display: none;
  }

  .table tr{
  display: block;
  margin-bottom: 40px;
  }

  .table td {
  display: block;
  text-align: right;
  }

  .table td:before {
  content: attr(data-label);
  float: left;
  font-weight: bold;
  }
  }
  
  @media only screen and (min-device-width: 1024px) {
    .mesHisto{
      background-color: white;
        margin-top:0%;
        border-radius: 28px;
        padding-top:7%
    }
  
    .pourboire{
        text-align: center;
    }
  }