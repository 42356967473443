.bloc-modifierProfil body {
  background-color: antiquewhite;
}

Input {
  border-radius: 38px!important;
}

.form-control {
  border-radius: 38px!important;
}

.bloc-modifierProfil {
  background: #ffffff;
  font: bold 12px;
  margin-bottom: 45px;
  color: #4a4a4a;
  margin-top: 3%;
  margin-top: -10%;
  padding: 44px;
  border-radius:28px;
}

.bloc-modifierProfil .form-group .form-control {
  font-size: 0.8rem;
}
.bloc-modifierProfil .form-label {
  color: #4a4a4a;
}

.bloc-modifierProfil .photoModif .TextProfil {
  margin-bottom: 1rem;
  margin-top: -30px;
  color: #4a4a4a;
  font-size: initial;
  margin-left: 120px;
}
.bloc-modifierProfil .submitButton {
  border-radius: 28px !important;
  background-color: #f5a624 !important;
  width: 100%;
  border-color: #f5a624 !important;
  padding-top: 3% !important;
  padding-bottom: 3% !important;
  font-size: 23px !important;
  color: #ffffff;
  margin-bottom: 5%;
}
.bloc-modifierProfil .submitButton:hover,
.bloc-modifierProfil .submitButton:active {
  border-radius: 28px !important;
  background-color: #f5a624 !important;
  border-color: #f5a624 !important;
  font-size: 23px !important;
  color: #ffffff;
  margin-bottom: 5%;
}
.bloc-modifierProfil .photoModif .pictureButton {
  background-color: #f4a521;
  font-size: 0.8rem;
  border-radius: 8px;
  border: 2px solid #f4a521 !important;
}
.bloc-modifierProfil .titreModif {
  text-align: left;
  color: #4a4a4a;
  font-size: 20px;
}

.bloc-modifierProfil .chargePic {
  font-size: 0.8rem;
  border-radius: 28px;
  margin-bottom: 5%;
}

.button {
  border-radius: 28px !important;
  background-color: #f5a624 !important;
  width: 100%;
  border-color: #f5a624 !important;
  padding-top: 3% !important;
  padding-bottom: 3% !important;
  font-size: 23px !important;
  color: #ffffff;
  margin-bottom: 5%;
  border-width: 0px;
}

.bloc-modifierProfil .serveurPicture {
  width: 50%;
  border-radius: 28px;
}

@media only screen and (min-device-width: 1024px) {

  .bloc-modifierProfil {
    margin-left: 20%;
    margin-right: 20%;
  }
  .bloc-modifierProfil {
    background: #ffffff;
    font: bold 12px;
    margin-bottom: 45px;
    color: #4a4a4a;
    margin-top: 3%;
    padding: 44px;
  }

  .bloc-modifierProfil .titreModif{
    margin-left:0%!important
  }

  .button {
    background-color: #f5a624 !important;
    width: 22%;
    border-color: #f5a624 !important;
    padding-top: 1% !important;
    padding-bottom: 1% !important;
    font-size: 23px !important;
    color: #ffffff;
    margin-bottom: 5%;
    border-width: 0px;
  }

  .bloc-modifierProfil .submitButton {
    background-color: #f5a624 !important;
    width: 50%;
    border-color: #f5a624 !important;
    padding-top: 1% !important;
    padding-bottom: 1% !important;
    font-size: 23px !important;
    color: #ffffff;
    margin-bottom: 5%;
    border-width: 0px;
  }

  .bloc-modifierProfil .form-control{
    height: calc(2.5em + 1.75rem + 2px)!important;
  }

  .submitButton:active {
    background-color: #f5a624 !important;
    width: 50%;
    border-color: #f5a624 !important;
    padding-top: 1% !important;
    padding-bottom: 1% !important;
    font-size: 23px !important;
    color: #ffffff;
    margin-bottom: 5%;
    border-width: 0px;
  }

  .submitButton:hover {
    background-color: #f5a624 !important;
    width: 50%;
    border-color: #f5a624 !important;
    padding-top: 1% !important;
    padding-bottom: 1% !important;
    font-size: 23px !important;
    color: #ffffff;
    margin-bottom: 5%;
  }
  .bloc-modifierProfil .form-group .form-control {
    font-size: 1.2rem;
  }
  .bloc-modifierProfil .form-label {
    color: #4a4a4a;
  }
}
