.submitButtonSub {
  border-radius: 28px;
  background-color: #f5a624;
  color: white;
  border: none;
  margin-bottom: 12px;
  font-size: 22px;
  padding: 1%;
  margin-top: 3%;
}
.submitButtonSub:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}

.checkboxRet {
  color: #f5a624 !important;
  font-weight: bold;
  margin-top: 3%;
}
