.cagnotteCont {
  border-radius: 28px;
  background-color: white;
  margin-top: -10%;
  padding-top: 10%;
  padding-bottom: 25%;
}

.cagnotteCont button {
  border-radius: 52px;
  background-color: #f5a624;
  border-color: #f5a624;
  margin-top: 5%;
}

.cagnotteCont button:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}

.butBankAcc:active {
  background-color: #555 !important ;
}
.butBankAcc:focus {
  background-color: #555 !important ;
}

.rowCagn {
  text-align: center;
}

.rowCagn h2 {
  color: #f5a624;
}

.rowPasDeCagn {
  text-align: center;
  margin-top: 10%;
  margin-bottom: 10%;
}

.rowId {
  text-align: center;
  margin-bottom: 15%;
}

.rowId h5 {
  margin-bottom: 5%;
}
.titleKyc {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  font-size: 30px;
}
.rappelKYC {
  font-family: "Montserrat", sans-serif !important;
  font-weight: lighter !important;
  font-size: 15px;
}
/**
* CSS Statut KYC
*/
.kycStatut {
  padding: 35px;
  border-radius: 30px;
  margin-left: 30px;
}
.kycTitleStatut {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  font-size: 26px;
}
.statut {
  padding: 5px;
}

.statutValid {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 800;
  border-radius: 20px;
}
.statutRefused {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 800;
  border-radius: 20px;
}
.form-control {
  padding: 0.375rem 0.75rem !important;
}

.statutIn {
  font-family: "Montserrat", sans-serif !important;
  font-weight: bolder;
  border-radius: 20px;
}

@media only screen and (min-device-width: 1024px) {
  .cagnotteCont {
    margin-top: 0%;
  }
}
