body {
  background-color: white !important;
  font-family: "Montserrat", sans-serif !important;
}

.inscr {
  background: #ffffff;
  width: 100%;
  margin-bottom: 45px;
  border-radius: 28px;
  border: none;
  color: #555;
  margin-top: -13%;
  padding: 70px;
}
.inscr .form-control {
  height: 66px;
  font-size: 0.8rem;
}

.inscr .annonce {
  margin-top: 20px;
  color: #f5a624;
  font-size: 12px;
}
.inscr .RowInscr {
  padding-left: 8% !important;
  padding-right: 8% !important;
}

.inscr .checkboxCGU {
  font-weight: bold;
  color: #f5a624;
}
.titreh1 {
  color: #f4a521;
}
.Titrep {
  font-size: 14px;
}
.cgvLink {
  color: #f5a624 !important;
  text-decoration: underline !important;
}

.inscr .Bloc-principal .form-group .form-control {
  font-size: 0.8rem;
  border-radius: 8px;
}

.inscr .Bloc-principal .form-label {
  color: #4a4a4a;
}

.inscr .Bloc-principal .submitButton {
  background-color: #4a4a4a;
  font-size: 0.8rem;
  border-radius: 8px;
  border: 2px solid #4a4a4a !important;
}

.inscr .Bloc-principal .submitButton:hover,
.inscr .Bloc-principal .submitButton:active {
  background-color: #f4a521;
  color: #4a4a4a;
}

.Bloc-principal a {
  text-decoration: none !important;
}

.inscr .Bloc-principal .Titre {
  text-align: left;
  color: #4a4a4a;
  font-size: 20px;
}

.inscr .connectServeur {
  border-radius: 28px !important;
  background-color: #f5a624 !important;
  width: 100%;
  border-color: #f5a624 !important;
  padding-top: 3% !important;
  padding-bottom: 3% !important;
  font-size: 23px !important;
}
.inscr .connectServeur:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}

.imageInscrServeur {
  display: none;
}

.pwd-container {
  position: relative;
  width: 100%;
}
.pwd-container img {
  cursor: pointer;
  position: absolute;
  width: 30px;
  right: 31px;
  top: 16px;
}

@media only screen and (min-device-width: 1024px) {
  .inscr .connectServeur {
    background-color: #f5a624 !important;

    border-color: #f5a624 !important;
    padding-top: 1% !important;
    padding-bottom: 1% !important;
    font-size: 23px !important;
  }
  .inscr {
    background: #ffffff;
    width: 100%;
  }

  .Titre {
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .imageInscrServeur {
    display: block;
    background-image: url("/images/drink (7).jpg");
    background-repeat: no-repeat;
  }
}
