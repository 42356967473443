.passwordReset {
  background-color: #ffffff;
  border-radius: 28px;
  margin-top: -10%;
}
.formReset input {
  border-radius: 28px !important;
  width: 100% !important;
  padding: 12px 20px !important;
  margin: 8px 0 !important;
  box-sizing: border-box !important;
  margin-left: 1% !important;
  margin-right: 1% !important;
  border-width: 1px;
  border-style: inset;
  border-color: #80808057;
}
.resetButton {
  border-radius: 24px !important;
  background-color: #f5a624 !important;
  color: white !important;
  width: 150px !important;
  font-size: 20px !important;
  font-family: "Montserrat", sans-serif !important;
  transition-duration: 0.4s !important;
  margin-left: 0% !important;
  border-color: #f5a624 !important;
}
.resetButton:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}

.ButtonReset {
  text-align: center;
  margin-bottom: 10%;
}

.PassResetDiv {
  margin-top: 72px !important;
  text-align: center;
}
.titrePass {
  margin-bottom: 5%;
}

.taille {
  font-size: 20px;
}

@media only screen and (min-device-width: 1024px) {
  .passwordReset {
    margin-top: 0%;
    margin-right: auto;
  }
  .formReset input {
    border-radius: 28px !important;
    width: 30% !important;
    padding: 12px 20px !important;
    margin: 8px 0 !important;
    box-sizing: border-box !important;
    margin-left: 1% !important;
    margin-right: 1% !important;
  }
  .resetButton {
    border-radius: 24px !important;
    background-color: #f5a624 !important;
    color: white !important;
    width: 150px !important;
    font-size: 20px !important;
    font-family: "Montserrat", sans-serif !important;
    transition-duration: 0.4s !important;
    margin-left: 0% !important;
    border-color: #f5a624 !important;
  }
  .ButtonReset {
    text-align: center;
    margin-bottom: 10%;
  }

  .inputReset {
    border-width: 1px;
    border-style: inset;
    border-color: #80808057;
  }
  .resetButton:hover {
    background-color: #f5a624 !important;
    color: white !important;
    border-color: #f5a624 !important;
  }
  .PassResetDiv {
    text-align: center;
  }

  .titrePass {
    margin-bottom: 5%;
  }
}
