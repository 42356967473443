.monAbonnement {
  background-color: #ffffff;
  border-radius: 28px;
  margin-top: -10%;
  padding-top: 10%;
}

.monAbonnement .infoAbonnement {
  padding: 20px;

  text-align: center;
}
.monAbonnement .buttonValid {
  margin-top: 10%;
  border-radius: 28px !important;
  background-color: #f5a624 !important;
  width: 100%;
  border-color: #f5a624 !important;
  padding-top: 3% !important;
  padding-bottom: 3% !important;
  font-size: 23px !important;
  color: #ffffff;
  margin-bottom: 5%;
}

.monAbonnement .ajouterDoc {
  padding: 29px;

  text-align: center;

  margin-bottom: 20%;
}
.monAbonnement .infoAbonnement .annulation {
  text-decoration: none;
  color: #f4a521;
  font-size: large;
}

.stripe {
  margin-top: 10%;
  border-radius: 28px !important;
  background-color: #f5a624 !important;
  width: 100%;
  border-color: #f5a624 !important;
  padding-top: 3% !important;
  padding-bottom: 3% !important;
  font-size: 23px !important;
  border-width: 0px;
}

.stripe a {
  color: #ffffff !important;
}
body {
  background-color: #efefef;
}

.Bloc-principal {
  background: #ffffff;
  font: bold 12px Verdana;

  margin: auto;
  margin-bottom: 45px;
  border-radius: 12px 9px 12px;
  border: none;
  color: #555;
  border-left: 8px solid #f4a521;
  margin-top: -30px;
  box-shadow: 1px 1px 3px #999;
  padding: 70px;
}

.Bloc-principal .form-group .form-control {
  font-size: 0.8rem;
  border-radius: 8px;
}
.Bloc-principal .form-label {
  color: #f4a521;
}

.Bloc-principal .submitButton {
  background-color: #f4a521;
  font-size: 0.8rem;
  border-radius: 8px;
  border: 2px solid #f4a521 !important;
}
.Bloc-principal .submitButton:hover,
.Bloc-principal .submitButton:active {
  background-color: white;
  color: #f4a521;
}

@media only screen and (min-device-width: 1024px) {
  .monAbonnement .buttonValid {
    margin-top: 10%;
    border-radius: 28px !important;
    background-color: #f5a624 !important;
    width: 50%;
    border-color: #f5a624 !important;
    padding-top: 1% !important;
    padding-bottom: 1% !important;
    font-size: 23px !important;
    color: #ffffff;
    margin-bottom: 5%;
  }

  .monAbonnement{
    margin-top:0%
  }

  .stripe {
    margin-top: 10%;
    border-radius: 28px !important;
    background-color: #f5a624 !important;
    width: 50%;
    border-color: #f5a624 !important;
    padding-top: 1% !important;
    padding-bottom: 1% !important;
    font-size: 23px !important;
    color: #ffffff;
    margin-bottom: 5%;
  }
}
