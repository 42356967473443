.statut {
  margin-left: 25%;
  margin-right: 25%;
}

.statutIn {
  background-color: orange;
}

.statutRefused {
  background-color: red;
  color: white;
}

.statutValid {
  background-color: green;
}

.kycStatut {
  text-align: center;
}
.rowId {
  justify-content: center;
}
