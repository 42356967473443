.main-container {
  background-color: #ffffff;
}

.contain {
  border-radius: 28px;
  margin-top: -8%;
  background: #ffffff;
  padding-top: 18%;
  margin-bottom: 20%;
}

.contain .button:hover {
  background-color: #4a4a4a;
}

.contain .infoProfil {
  text-align: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.contain .ligne {
  margin-bottom: 2%;
  color: #f4a521;
  font-size: 38px !important;
  margin-bottom: 5%;
}

.contain .monrestaurant {
  border-radius: 12px 9px 12px;
  background: #ffffff;
  border: none;
  color: #4a4a4a;

  margin-top: 5%;
  text-align: center;
}

.contain .colPhoto {
  text-align: center;
}

body {
  background-color: #4a4a4a !important;
  font-family: "Montserrat", sans-serif !important;
}

.contain .btnDelete {
  text-align: right;
}

.contain .tips {
  margin-top: 10%;
}

.contain .titreNice {
  margin-bottom: 1em;
  color: #f4a521;
  font-style: inherit;
}

.centerPic {
  justify-content: center;
}
/**
*Style parrainage 
*/
.parrainage .inputParrainage {
  padding: 0.5rem;
  border-radius: 28px;
  border-width: 0.01px;
  border-color: grey;
  text-align: center !important;
  margin-right: 3%;
  margin-top: 3%;
}
.parrainage .demandeParrainage {
  color: #f4a521;
  font-size: 34px !important;
}
.parrainage .colParrainage {
  text-align: center !important;
  margin-top: 10%;
}
.parrainage .buttonParrainage {
  border-radius: 28px !important;
  background-color: #f5a624 !important;
  margin-left: 1%;
  border-color: #f5a624 !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  font-size: 16px !important;
  border: 0px solid #4a4a4a !important;
  color: white;
  border-width: 0px;
}

.parrainage .buttonParrainage:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}
.parrainage .infoParrainage {
  font-weight: bold;
  margin-top: 2% !important;
}
.contain a {
  text-decoration: none;
}

.contain .btn-primary {
  background-color: #4a4a4a;
  border-color: #4a4a4a;
}
.contain .buttonDeleteAffi {
  font-size: 20px;
  opacity: 0.5;
  width: 11%;

  border-radius: 11px;
  background-color: #f4a521;
}

.contain .profilPara {
  margin-bottom: 0%;
  font-size: 20px;
}
.contain .profilParafin {
  font-size: 20px;
}

.container .restauAffil {
  margin-bottom: 11%;
}

.contain .photoProfil {
  width: 43%;
  margin-bottom: 14%;
}

.rowDocument {
  align-items: flex-end;
}

.contain .buttonModifier {
  border-radius: 28px !important;
  background-color: #f5a624 !important;
  width: 100%;
  border-color: #f5a624 !important;
  padding-top: 3% !important;
  padding-bottom: 3% !important;
  font-size: 23px !important;
  color: #ffffff;
  border-width: 0px;
  margin-top: 3%;
}
.contain .buttonModifier:hover,
.contain .buttonModifier:active {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}

.rowHisto {
  justify-content: center;
}

.contain .buttonAbo {
  border-radius: 28px !important;
  background-color: #f4a521 !important;
  width: 100%;
  border-color: #f5a624 !important;
  padding-top: 3% !important;
  padding-bottom: 3% !important;
  font-size: 23px !important;
  color: #ffffff;

  border-width: 0px;
}
.contain .buttonAbo:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}

/**
*Modal Style
*/
.modalTitle {
  font-family: "Montserrat", sans-serif !important;
  font-size: 24px !important;
  font-weight: 900 !important;
  color: #f5a624 !important;
  text-align: center !important;
}

.modalBody {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  font-size: 18px;
}

.modalButton {
  background-color: #f4a521 !important;
  color: white !important;
  border-radius: 58px !important;
  border-color: #f5a624 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}
.modalButton:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}

.lienCommentaire {
  font-size: 36px;
  background-color: #f4a521 !important;
  color: white !important;
  border-radius: 58px !important;
  border-color: #f5a624 !important;
  margin-top: 5%;
}

.buttonRef {
  text-align: center;
}
.referentButton {
  border-radius: 28px !important;
  background-color: #f5a624 !important;
  width: 100%;
  border-color: #f5a624 !important;
  padding-top: 3% !important;
  padding-bottom: 3% !important;
  font-size: 23px !important;
  color: #ffffff;
  border-width: 0px;
  margin-top: 3%;
}
.referentButton:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}

/**
* Style modal tuto
*/

.buttonTuto {
  background-color: #7200ff !important;
  position: -webkit-sticky !important;
  font-size: 20px !important;
  z-index: 1000 !important;
  right: -6% !important;
  top: 45% !important;
  position: fixed !important;
  float: right !important;
  border-width: 0 !important;
  padding: 2% 2% 2% 2% !important;
  border-radius: 0px 0px 25px 25px !important;
  vertical-align: top !important;
  transform: rotate(90deg) !important;
  -ms-transform: rotate(90deg) !important; /* IE 9 */
  -moz-transform: rotate(90deg) !important; /* Firefox */
  -webkit-transform: rotate(90deg) !important; /* Safari and Chrome */
  -o-transform: rotate(90deg) !important; /* Opera */
}

.flecheTuto {
  margin-left: 0.4rem !important;
  background-color: #f5a624 !important;
  color: #7200ff !important;
  border-width: 0 !important;
  vertical-align: top !important;
  border-radius: 25px !important;
  transform: rotate(90deg) !important;
  -ms-transform: rotate(270deg) !important; /* IE 9 */
  -moz-transform: rotate(270deg) !important; /* Firefox */
  -webkit-transform: rotate(270deg) !important; /* Safari and Chrome */
  -o-transform: rotate(270deg) !important; /* Opera */
}
.flecheTuto:hover {
  border-color: #f5a624 !important;
  color: white !important;
}
.titleTutoServ {
  font-size: 22px !important;
  text-align: center !important;
}
.paraTuto {
  font-weight: 800 !important;
  font-size: 18px;
  text-align: center !important;
}
.textTutoServ {
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  font-size: 14px !important;
}

@media (max-width: 800px) {
  .contain {
    margin-right: auto;
  }

  .marginPhone {
    margin-bottom: 3%;
  }
  .container .photoProfil {
    margin-left: 0%;
  }
  .container .monrestaurant {
    margin-top: 17%;
    margin-bottom: 4%;
    text-align: center;
  }
  .container .monrestaurant h1 {
    font-size: 30px;
  }
  .container .mesdetails {
    text-align: center;
  }

  .contain .titre {
    font-size: 2.5rem;
  }

  .container .profilParaFin {
    margin-bottom: 2%;
  }

  .contain .lienCommentaire {
    font-size: 23px !important;
    background-color: #f4a521 !important;
    color: white;
    border-radius: 58px !important;
    width: 100%;
    padding-top: 3% !important;
    padding-bottom: 3% !important;
  }
  .contain .lienCommentaire:hover {
    background-color: #555 !important;
    color: #f5a624 !important;
    border-color: #f5a624 !important ;
  }

  .container .delete {
    margin-top: 4%;
    text-align: right;
  }
  .StripeElement {
    width: 278px !important;
    margin-top: 10px !important;
  }

  /**
*TUTO
*/
  .buttonTuto {
    background-color: #7200ff !important;
    position: -webkit-sticky !important;
    font-size: 20px !important;
    z-index: 1000 !important;
    right: -24% !important;
    position: fixed !important;
    float: right !important;
    border-width: 0 !important;
    padding: 3% 3% 3% 3% !important;
    border-radius: 0px 0px 25px 25px !important;
    vertical-align: top !important;
    transform: rotate(90deg) !important;
    -ms-transform: rotate(90deg) !important; /* IE 9 */
    -moz-transform: rotate(90deg) !important; /* Firefox */
    -webkit-transform: rotate(90deg) !important; /* Safari and Chrome */
    -o-transform: rotate(90deg) !important; /* Opera */
  }

  .flecheTuto {
    margin-left: 0.4rem !important;
    background-color: #f5a624 !important;
    color: #7200ff !important;
    border-width: 0 !important;
    border-radius: 25px !important;
    vertical-align: top !important;
    transform: rotate(90deg) !important;
    -ms-transform: rotate(270deg) !important; /* IE 9 */
    -moz-transform: rotate(270deg) !important; /* Firefox */
    -webkit-transform: rotate(270deg) !important; /* Safari and Chrome */
    -o-transform: rotate(270deg) !important; /* Opera */
  }
  .flecheTuto:hover {
    border-color: #f5a624 !important;
    color: white !important;
  }
  .titleTutoServ {
    font-size: 22px !important;
    text-align: center !important;
  }

  .referentButton:hover {
    background-color: #555 !important;
    color: #f5a624 !important;
    border-color: #f5a624 !important ;
  }
}

@media only screen and (min-device-width: 1024px) {
  body {
    background-color: #4a4a4a !important;
    font-family: "Montserrat", sans-serif;
  }

  .contain .monrestaurant {
    margin-top: 3%;
  }
  .contain .photoProfil {
    width: 38% !important;
  }

  .contain {
    padding-top: 8% !important;
  }

  .contain .infoProfil {
    margin-top: 0%;
  }

  .infoProfil h1 {
    font-size: 50px;
  }

  .contain .buttonAbo {
    margin-top: 0% !important;
  }
  .contain {
    border-radius: 28px;
    margin-top: 0% !important;
    background: #ffffff;
    padding-top: 18%;
    margin-bottom: 20%;
  }
  .contain .photoProfil {
    width: 43%;
    margin-bottom: 5% !important;
  }
  .contain {
    border-radius: 28px;
    margin-top: -8%;
    background: #ffffff;
    padding-top: 2% !important;
    margin-bottom: 20%;
  }
  .lienCommentaire {
    font-size: 23px !important;
    background-color: #f4a521 !important;
    color: white;
    border-radius: 58px !important;
    width: 100%;
    padding-top: 3% !important;
    padding-bottom: 3% !important;
  }

  .rowButton {
    margin-top: 10%;
    align-items: flex-end;
  }
  .contain .ligne {
    margin-bottom: 2%;
    color: #f4a521;
    font-size: 38px !important;
    margin-bottom: 5% !important;
  }
  .titleTutoServ {
    font-size: 22px !important;
    text-align: center !important;
  }

  .referentButton:hover {
    background-color: #555 !important;
    color: #f5a624 !important;
    border-color: #f5a624 !important ;
  }
}
