body {
  font-family: "Montserrat", sans-serif !important;
}

.mesComs {
  background-color: white;
  margin-top: -10%;
  border-radius: 28px;
  padding-top: 7%;
}

.titreComs {
  margin-bottom: 5%;
  padding-left: 2%;
  font-size: 2.2rem;
  text-align: center;
}
.mesTips {
  padding-top: 8vh;
  padding-bottom: 8vh;
  background-color: #ffffff;
  border-radius: 28px;
  margin-top: -10%;
}

.mestips .commentaireBackground {
  background-color: #ffffff;
}

.mesTips .mesStatuts {
  color: black;
}

.divCom {
  background-color: #ffffff;
  border-radius: 28px;
  padding: 4%;
  margin-bottom: 2%;
}

.nomPrenom {
  display: flex;
}

.prenomCom {
  color: #f4a521;
}

.nomCom {
  margin-left: 1%;
}

.Nocoms {
  text-align: center;
}

.statut2 {
  font-size: 20px;
}

@media only screen and (min-device-width: 1024px) {
  .mesComs {
    background-color: white;
    margin-top: 0%;
    border-radius: 28px;
    padding-top: 7%;
  }
}
