.connexion-container a {
  color: #f5a624 !important;
  font-family: "Montserrat", sans-serif !important;
}
.connexion-container {
  margin-top: -5% !important;
  background-color: white !important;
  border-radius: 28px !important;
  padding-top: 3% !important;
  padding-left: 10% !important;
  padding-right: 10% !important;
  margin-bottom: 30% !important;
}

.connexion-container h1 {
  font-size: 22px !important;
  margin-top: 5% !important;
  font-weight: bold !important;
}

.connexion-container .form-control {
  border-radius: 28px !important;
  margin-top: 5% !important;
}

.connexion-container .pwd-container {
  position: relative;
  width: 100%;
}
.connexion-container .pwd-container img {
  cursor: pointer;
  position: absolute;
  width: 25px;
  right: 31px;
  top: 10px;
}

.forgetpwd {
  font-size: 18px !important;
  color: #e05858 !important;
  display: inherit !important;
  margin-top: -20px !important;
  text-decoration: none !important;
  margin-top: 10px !important;
  text-align: right !important;
}

.blocCompte {
  text-align: right !important;
}
.connectButton {
  border-radius: 28px !important;
  border-color: #f5a624 !important;
  background-color: #f5a624 !important;
  color: white !important;
  padding-left: 28% !important;
  padding-right: 28% !important;
}

.connectButton:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}

.text2 {
  font-size: 22px !important;
  font-weight: lighter !important;
  margin-top: 10px !important;
  margin-bottom: -10px !important;
  font-weight: bold !important;
}

.alignRight {
  text-align: right !important;
}

.form-control {
  padding: 1.375rem 0.75rem !important;
  margin-bottom: 3% !important;
}

@media only screen and (min-device-width: 1024px) {
  .connexion-container {
    margin-top: 0% !important;
  }
  .connexion-container h1 {
    font-size: 28px !important;
    margin-top: 0% !important;
  }
  .connexion-container .form-control {
    border-radius: 28px !important ;
    margin-top: 3% !important;
  }
  .text2 {
    font-size: 22px !important;
    font-weight: lighter !important;
    margin-top: 10px !important;
    margin-bottom: -10px !important;
    font-weight: bold !important;
  }
  .forgetpwd {
    font-size: 18px !important;
    color: #e05858 !important;
    display: inherit !important;
    margin-top: -20px !important;
    text-decoration: none !important;
    margin-top: 10px !important;
    text-align: right !important;
  }
  .form-control {
    padding: 1.6rem !important;
    margin-bottom: 2% !important;
    text-align: left !important;
  }
}
